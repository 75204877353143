import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACTIONS_CALL,
  CallApi,
  callSocket,
} from '@app/api';

import {
  BeepService,
  PersistanceService,
} from '@app/services';

import { CallType } from '@app/types';
import { useAppStore } from '@app/stores';

import {
  CallListUpdateEvent,
  CallRunEvent,
  DataGeneralSetEvent,
} from './types';


export interface MainContextInterface {
  operatorListLen: number;
  callListLen: number;
  callList: CallType[];
  callStartLoading: boolean;
  callStartByUuidLoading: string | null;
  callInFlight: CallType | undefined;
  callStart: (callUuid?: string) => void;
  callReject: (callId: number) => void;
  dataGeneralSet: (event: DataGeneralSetEvent) => void;
  callListUpdate: (event: CallListUpdateEvent) => void;
  callRun: (event: CallRunEvent) => void;
  callInFlightStart: () => void;
};

const documentTitleInitial = document.title;

export const useMainHook = (
): MainContextInterface => {
  const navigate = useNavigate();
  const { notifyCall } = useAppStore();

  const [ operatorListLen, operatorListLenSet ] = React.useState(0);
  const [ callListLen, callListLenSet ] = React.useState(0);
  const [ callList, callListSet ] = React.useState<CallType[]>([]);
  const [ callStartLoading, callStartLoadingSet ] = React.useState(false);
  const [ callStartByUuidLoading, callStartByUuidLoadingSet ] = React.useState<string | null>(null);
  const [ callInFlight, callInFlightSet ] = React.useState<CallType | undefined>(undefined);
  const [ needBeep, needBeepSet ] = React.useState(false);

  const callStart = React.useCallback(async (callUuid?: string) => {
    if (callUuid === undefined) callStartLoadingSet(true);
    else callStartByUuidLoadingSet(callUuid);

    const response = await  CallApi.callStartOperator({ callUuid })
    callStartLoadingSet(false);
    callStartByUuidLoadingSet(null);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Не удалось начать прием',
      });
    }

    navigate('/call-list-queue/' + response.data.callUuid);
  }, [
    navigate,
    notifyCall,
  ]);

  const callReject = React.useCallback((callId: number) => {
    callSocket.emit(ACTIONS_CALL.OPERATOR_CALL_REJECT, {
      jwt: PersistanceService.getToken(),
      callId,
    });
  }, []);

  const callListUpdate = React.useCallback((event: CallListUpdateEvent) => {
    if (callList.length < event.callList.length && needBeep) {
      BeepService.beep();
    }

    callListSet(event.callList);
    callInFlightSet(event.callInFlight);
    needBeepSet(true);
  }, [
    needBeep,
    callList,
  ]);

  const callRun = React.useCallback((event: CallRunEvent) => {
    navigate('/call-list-queue/' + event.callUuid);
  }, [
    navigate,
  ]);

  const callInFlightStart = React.useCallback(() => {
    if (!callInFlight) return;
    callStartByUuidLoadingSet(callInFlight.uuid);
    navigate('/call-list-queue/' + callInFlight.uuid);
    callStartByUuidLoadingSet(null);
  }, [
    callInFlight,
    navigate,
  ]);

  const dataGeneralSet = React.useCallback((event: DataGeneralSetEvent) => {
    callListLenSet(event.callListLen);
    operatorListLenSet(event.operatorListLen);
  }, []);

  React.useEffect(() => {
    let visibleFlag = false;
    if (callList.length === 0) {
      document.title = documentTitleInitial;
      return;
    }
    const interval = setInterval(() => {
      if (visibleFlag) {
        document.title = `${documentTitleInitial} (${callList.length})`;
        visibleFlag = false;
      } else {
        document.title = documentTitleInitial;
        visibleFlag = true;
      }
    }, 700);

    return () => {
      document.title = documentTitleInitial;
      clearInterval(interval);
    }
  }, [
    callList,
  ]);


  return React.useMemo(() => ({
    operatorListLen,
    callListLen,
    callList,
    callStartLoading,
    callStartByUuidLoading,
    callInFlight,
    dataGeneralSet,
    callListUpdate,
    callStart,
    callRun,
    callReject,
    callInFlightStart,
  }), [
    operatorListLen,
    callListLen,
    callList,
    callStartLoading,
    callStartByUuidLoading,
    callInFlight,
    dataGeneralSet,
    callListUpdate,
    callStart,
    callRun,
    callReject,
    callInFlightStart,
  ]);
};

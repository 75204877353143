import React from 'react';

import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  CardActionsBrick,
  DividerHorizontalBrick,
  FieldSelectBlock,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';

import {
  useMainContext,
  useGeneralUpdateContext,
} from '../../context';


export const AppAccessUpdateComponent = () => {
  const {
    appAccessIsEditing,
    appAccessFormClose,
  } = useMainContext();

  const {
    formValue,
    formDirty,
    formIsSumbitting,
    onSubmit,
    changeFormValue,
  } = useGeneralUpdateContext();

  if (formValue === null || !appAccessIsEditing) return null;

  return (
    <React.Fragment>
      <TableContainerBrick style={{ overflow: 'visible' }}>
        <TableBrick>
          <TableHeadBrick>
            <TableRowBrick>
              <TableCellBrick>Лицензия</TableCellBrick>
              <TableCellBrick>Очередь звонков</TableCellBrick>
              <TableCellBrick>Список пропущенных</TableCellBrick>
              <TableCellBrick>Общий журнал звонков</TableCellBrick>
              <TableCellBrick>Консультации</TableCellBrick>
              <TableCellBrick>Общий журнал консультаций</TableCellBrick>
            </TableRowBrick>
          </TableHeadBrick>
          <TableBodyBrick>
            <TableRowBrick
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.LICENSE_ACCESS}
                  name="LICENSE_ACCESS"
                  label="Лицензия"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.CALL_QUEUE_ACCESS}
                  name="CALL_QUEUE_ACCESS"
                  label="Очередь звонков"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.CALL_LIST_MISSED_ACCESS}
                  name="CALL_LIST_MISSED_ACCESS"
                  label="Список пропущенных"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.CALL_LIST_GENERAL_ACCESS}
                  name="CALL_LIST_GENERAL_ACCESS"
                  label="Общий журнал звонков"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.CONSULTATION_ACCESS}
                  name="CONSULTATION_ACCESS"
                  label="Консультации"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.CONSULTATION_LIST_GENERAL_ACCESS}
                  name="CONSULTATION_LIST_GENERAL_ACCESS"
                  label="Общий журнал консультаций"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
            </TableRowBrick>
          </TableBodyBrick>
        </TableBrick>
      </TableContainerBrick>
      <DividerHorizontalBrick />
      <CardActionsBrick>
        <ButtonSaveBlock
          loading={formIsSumbitting}
          onClick={onSubmit}
          disabled={!formDirty}
        />
        <ButtonCancelBlock onClick={appAccessFormClose}/>
      </CardActionsBrick>
    </React.Fragment>
  );
};

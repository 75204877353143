import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ConsultationApi } from '@app/api';
import { translateErrorHelper } from '@app/helpers';
import { useAppStore } from '@app/stores';
import { BackendErrorsType, ChangeFieldEventType } from '@app/types';


const initialFormValue = {
  consultationId: '',
};

export interface MainContextInterface {
  formValue: typeof initialFormValue;
  formDirty: boolean;
  formIsSubmitting: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
};

export const useMainHook = (
): MainContextInterface => {
  const navigate = useNavigate();
  const [ formValue, formValueSet ] = React.useState(initialFormValue);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSubmitting, formIsSubmittingSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);
  const { notifyCall } = useAppStore();

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    formIsSubmittingSet(true);
    const response = await ConsultationApi.create(formValue);
    formIsSubmittingSet(false);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось создать консультацию.',
      });

      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    notifyCall({
      type: 'success',
      message: 'Консультация успешно создана.'
    });
    navigate(`/consultation-list/${response.data.consultation.uuid}`);
  }, [
    formValue,
    notifyCall,
    navigate,
  ]);

  const errorSet = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && translateErrorHelper(errors[fieldName][0]);
  }, [
    errors,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    onSubmit,
    errorSet,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    onSubmit,
    errorSet,
  ]);
};

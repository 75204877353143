import {
  ButtonCancelBlock,
  ButtonEditBlock,
  CardDataComposition,
} from '@app/components';

import { useMainContext } from '../../context';


export const AppAccessComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    oneIsLoading,
    appAccessIsEditing,
    appAccessFormOpen,
    appAccessFormClose,
  } = useMainContext();

  return (
    <CardDataComposition
      title="Доступ к функционалу"
      extra={
        <>
          {!appAccessIsEditing && <ButtonEditBlock onClick={() => appAccessFormOpen()} />}
          {appAccessIsEditing && <ButtonCancelBlock onClick={() => appAccessFormClose()} buttonIcon />}
        </>
      }
      loading={oneIsLoading}
    >
      {children}
    </CardDataComposition>
  );
}

import {
  PageBaseLayout,
  RoleListFeature,
} from '@app/components'


export const RoleListPage = () => {
  return (
    <PageBaseLayout breadcrumbs={[
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/' },
    ]}>
      <RoleListFeature />
    </PageBaseLayout>
  );
};

import {
  ButtonBackBlock,
  ButtonSearchBlock,
  CardDataComposition,
} from '@app/components';

import { NoteSmart } from '@app/components'

import { FilterProvider, useMainContext } from '../../context';
import { FilterComponent } from '../filter';
import { ListComponent } from '../list';


export const MainComponent = () => {
  const {
    list,
    listIsLoading,
    filterOpened,
    filterCount,
    callSelected,
    callModalClose,
    filterOpen,
    filterClose,
    callUpdate,
  } = useMainContext();

  return (
    <CardDataComposition
      title="Общий журнал звонков"
      noData={list.length === 0 && !filterOpened}
      loading={listIsLoading}
      extra={<>
        {!filterOpened && <ButtonSearchBlock onClick={filterOpen} buttonIcon count={filterCount}/>}
        <ButtonBackBlock buttonIcon onClick={filterOpened ? filterClose : undefined} />
      </>}
    >
      {filterOpened ? (
        <FilterProvider>
          <FilterComponent />
        </FilterProvider>
      ) : (
        <ListComponent />
      )}

      <NoteSmart
        entityId={(callSelected && callSelected.id) || null}
        type="call"
        title={String(callSelected?.id)}
        note={callSelected?.note || ''}
        closeModal={callModalClose}
        entityUpdate={callUpdate}
      />
    </CardDataComposition>
  );
};

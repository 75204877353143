import { RoleAccessType } from '@app/types';


export const tranlsateRoleAccessHelper = (value: RoleAccessType): string => {
  if (value === 'ALL') {
    return 'Да';
  }

  if (value === 'NEVER') {
    return 'Нет';
  }

  return '';
}

import React from 'react';
import { useParams } from 'react-router-dom';

import { RoleType } from '@app/types';
import { RoleApi } from '@app/api';
import { useAppStore } from '@app/stores';


export interface MainContextInterface {
  roleId: number | null;
  one: RoleType | null;
  oneIsLoading: boolean;
  generalIsEditing: boolean;
  userAccessIsEditing: boolean;
  appAccessIsEditing: boolean;
  oneSet: React.Dispatch<React.SetStateAction<RoleType | null>>;
  generalFormOpen: () => void;
  generalFormClose: () => void;
  userAccessFormOpen: () => void;
  userAccessFormClose: () => void;
  appAccessFormOpen: () => void;
  appAccessFormClose: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { id } = useParams();
  const { notifyCall } = useAppStore();
  const [ one, oneSet ] = React.useState<RoleType | null>(null);
  const [ oneIsLoading, oneIsLoadingSet ] = React.useState(false);
  const [ generalIsEditing, generalIsEditingSet ] = React.useState(false);
  const [ userAccessIsEditing, userAccessIsEditingSet ] = React.useState(false);
  const [ appAccessIsEditing, appAccessIsEditingSet ] = React.useState(false);

  const roleId = React.useMemo(() => {
    if (!id || isNaN(Number(id))) return null;
    return Number(id);
  }, [id]);

  const oneDownload = React.useCallback(async () => {
    if (roleId === null) return;

    oneIsLoadingSet(true);
    const response = await RoleApi.one({ id: roleId });
    oneIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке роли.'
      });
    }

    oneSet(response.data.one);
  }, [
    roleId,
    notifyCall,
  ]);
  
  const generalFormOpen = React.useCallback(() => {
    generalIsEditingSet(true);
  }, []);

  const generalFormClose = React.useCallback(() => {
    generalIsEditingSet(false);
  }, []);
  
  const userAccessFormOpen = React.useCallback(() => {
    userAccessIsEditingSet(true);
  }, []);

  const userAccessFormClose = React.useCallback(() => {
    userAccessIsEditingSet(false);
  }, []);
  
  const appAccessFormOpen = React.useCallback(() => {
    appAccessIsEditingSet(true);
  }, []);

  const appAccessFormClose = React.useCallback(() => {
    appAccessIsEditingSet(false);
  }, []);

  React.useEffect(() => {
    oneDownload();
  }, [oneDownload]);

  return React.useMemo(() => ({
    roleId,
    one,
    oneIsLoading,
    generalIsEditing,
    userAccessIsEditing,
    appAccessIsEditing,
    generalFormOpen,
    generalFormClose,
    userAccessFormOpen,
    userAccessFormClose,
    appAccessFormOpen,
    appAccessFormClose,
    oneSet,
  }), [
    roleId,
    one,
    oneIsLoading,
    generalIsEditing,
    userAccessIsEditing,
    appAccessIsEditing,
    generalFormOpen,
    generalFormClose,
    userAccessFormOpen,
    userAccessFormClose,
    appAccessFormOpen,
    appAccessFormClose,
    oneSet,
  ]);
};

import { CallType } from '@app/types';
import React from 'react';
import { useParams } from 'react-router-dom';


export interface MainContextInterface {
  callUuid: string | undefined;
  callCurrent: CallType | null;
  peerConnection: React.MutableRefObject<RTCPeerConnection | null>;
  localMediaStream: React.MutableRefObject<MediaStream | null>;
  peerMediaElements: React.MutableRefObject<{ [key: string]: HTMLVideoElement | null; }>
  noteDrawerOpened: boolean;
  noteDrawerOpen: () => void;
  noteDrawerClose: () => void;
  callCurrentSet: React.Dispatch<React.SetStateAction<CallType | null>>;
};

export const useMainHook = (
): MainContextInterface => {
  const { uuid: callUuid } = useParams();

  const peerConnection = React.useRef<RTCPeerConnection | null>(null);
  const localMediaStream = React.useRef<MediaStream | null>(null);
  const peerMediaElements = React.useRef<{ [key: string]: HTMLVideoElement | null }>({});
  const [noteDrawerOpened, noteDrawerOpenedSet] = React.useState(false);
  const [callCurrent, callCurrentSet] = React.useState<CallType | null>(null);

  const noteDrawerOpen = React.useCallback(() => {
    noteDrawerOpenedSet(true);
  }, []);

  const noteDrawerClose = React.useCallback(() => {
    noteDrawerOpenedSet(false);
  }, []);

  return React.useMemo(() => ({
    peerConnection,
    localMediaStream,
    peerMediaElements,
    callUuid,
    noteDrawerOpened,
    callCurrent,
    callCurrentSet,
    noteDrawerOpen,
    noteDrawerClose,
  }), [
    peerConnection,
    localMediaStream,
    peerMediaElements,
    callUuid,
    noteDrawerOpened,
    callCurrent,
    callCurrentSet,
    noteDrawerOpen,
    noteDrawerClose,
  ]);
}

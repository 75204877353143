import React from 'react';

import {
  ChangeFieldEventType,
  HandbkInterface,
} from '@app/types';

import { UserApi } from '@app/api';
import { useAppStore } from '@app/stores';
import { useMainContext } from '../main';


const initialFormValue = {
  consultationId: '',
  consultantId: undefined,
  status: undefined,
};

export interface FilterContextInterface {
  formValue: typeof initialFormValue;
  formDirty: boolean;
  operatorList: HandbkInterface;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e?: React.SyntheticEvent) => void;
};


export const useFilterHook = (
): FilterContextInterface => {
  const { notifyCall } = useAppStore();
  const { listDownload, filterClose, filterPayload } = useMainContext();
  
  const [ formValue, formValueSet ] = React.useState(filterPayload as typeof initialFormValue || initialFormValue);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ operatorList, operatorListSet ] = React.useState<HandbkInterface>({ items: [] });

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onSubmit = React.useCallback((e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    filterClose();
    
    // formIsSubmittingSet(false);
    // formSendedSet(true);
    // errorsSet(null);
    
    listDownload(formValue);
  }, [
    formValue,
    filterClose,
    listDownload,
  ]);

  const operatorListLoad = React.useCallback(async () => {
    const result = await UserApi.handbk();
  
    if (!result.success) {
      return notifyCall({
        type: 'error',
        message: 'Не удалось загрузить список операторов',
      });
    }

    operatorListSet(result.data.handbk);
  }, [
    notifyCall,
  ]);

  React.useEffect(() => {
    operatorListLoad();
  }, [
    operatorListLoad,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    operatorList,
    changeFormValue,
    onSubmit,
  }), [
    formValue,
    formDirty,
    operatorList,
    changeFormValue,
    onSubmit,
  ]);
};

import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  CardActionsBrick,
  DividerHorizontalBrick,
  FieldSelectBlock,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';

import { useMainContext, useGeneralUpdateContext } from '../../context';
import React from 'react';


export const UserAccessUpdateComponent = () => {
  const {
    userAccessIsEditing,
    userAccessFormClose,
  } = useMainContext();

  const {
    formValue,
    formDirty,
    formIsSumbitting,
    onSubmit,
    changeFormValue,
  } = useGeneralUpdateContext();

  if (formValue === null || !userAccessIsEditing) return null;

  return (
    <React.Fragment>
      <TableContainerBrick style={{ overflow: 'visible' }}>
        <TableBrick>
          <TableHeadBrick>
            <TableRowBrick>
              <TableCellBrick>Таблица</TableCellBrick>
              <TableCellBrick>Создание</TableCellBrick>
              <TableCellBrick>Чтение</TableCellBrick>
              <TableCellBrick>Обновление</TableCellBrick>
            </TableRowBrick>
          </TableHeadBrick>
          <TableBodyBrick>
            <TableRowBrick
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCellBrick>Роли</TableCellBrick>
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.ROLE_CREATE}
                  name="ROLE_CREATE"
                  label="Создание"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.ROLE_READ}
                  name="ROLE_READ"
                  label="Чтение"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.ROLE_UPDATE}
                  name="ROLE_UPDATE"
                  label="Обновление"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
            </TableRowBrick>
            <TableRowBrick
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCellBrick>Пользователь</TableCellBrick>
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.USER_CREATE}
                  name="USER_CREATE"
                  label="Создание"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.USER_READ}
                  name="USER_READ"
                  label="Чтение"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
              <TableCellBrick>
                <FieldSelectBlock
                  value={formValue.USER_UPDATE}
                  name="USER_UPDATE"
                  label="Обновление"
                  onChange={changeFormValue}
                  items={[
                    { value: 'NEVER', label: 'Нет' },
                    { value: 'ALL', label: 'Да' },
                  ]}
                  hideError
                />
              </TableCellBrick>
            </TableRowBrick>
          </TableBodyBrick>
        </TableBrick>
      </TableContainerBrick>
      <DividerHorizontalBrick />
      <CardActionsBrick>
        <ButtonSaveBlock
          loading={formIsSumbitting}
          onClick={onSubmit}
          disabled={!formDirty}
        />
        <ButtonCancelBlock onClick={userAccessFormClose}/>
      </CardActionsBrick>
    </React.Fragment>
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACTIONS_CONSULTATIONS,
  consultationSocket,
} from '@app/api';

import { PersistanceService } from '@app/services';
import { CallConnectStatusType, ConsultationType } from '@app/types';

import { useMainContext } from '../main';
import { useDevicesContext } from '../devices';


export interface CallContextInterface {
  connectStatus: CallConnectStatusType;
  remoteCameraMuted: boolean;
  remoteMicrophoneMuted: boolean;
  remoteName: string | undefined;
  errorText: string;
  callStart: (e: { consultationUuid: string, clientIdCaller: string; consultation: ConsultationType; }) => Promise<void>;
  callStop: () => void;
  iceCandidateSet: (event: { iceCandidate: RTCIceCandidate }) => void;
  answerSet: (event: { answer: RTCSessionDescriptionInit }) => void;
  cameraToggleAndSend: () => void;
  microphoneToggleAndSend: () => void;
  callerDevicesSet: (event: { cameraMuted: boolean; microphoneMuted: boolean; }) => void;
  callerLeave: () => void;
  callerDisconnect: () => void;
};

export const useCallHook = (
): CallContextInterface => {
  const {
    consultationUuid,
    localMediaStream,
    peerMediaElements,
    peerConnection,
    consultationCurrentSet,
  } = useMainContext();
  
  const {
    mediaStart,
    mediaStop,
    cameraMuted,
    microphoneMuted,
    cameraToggle,
    microphoneToggle,
  } = useDevicesContext();

  const navigate = useNavigate();
  const [connectStatus, connectStatusSet] = React.useState<CallConnectStatusType>('pending');
  const [remoteMicrophoneMuted, remoteMicrophoneMutedSet] = React.useState(false);
  const [remoteCameraMuted, remoteCameraMutedSet] = React.useState(false);
  const [remoteName, remoteNameSet] = React.useState<string | undefined>(undefined);
  const [errorText, errorTextSet] = React.useState('');

  const consultantConnect = React.useCallback(async () => {
    await mediaStart();

    consultationSocket.emit(
      ACTIONS_CONSULTATIONS.CONSULTANT_CONNECT,
      {
        jwt: PersistanceService.getToken(),
        consultationUuid,
      },
    );
  }, [
    consultationUuid,
    mediaStart,
  ]);

  const callStart = React.useCallback(async (e: { consultationUuid: string, clientIdCaller: string; consultation: ConsultationType }) => {
    const { clientIdCaller, consultation } = e;
    remoteNameSet(consultation.consultationId);
    consultationCurrentSet(consultation);
    
    if (localMediaStream.current === null) return console.log('callStart - localMediaStream is null');

    peerConnection.current = new RTCPeerConnection({
      iceServers: [{
        urls: ['stun:80.90.189.135:3478']
      }],
    });

    try {
      for (const track of localMediaStream.current.getTracks()) {
        peerConnection.current.addTrack(track, localMediaStream.current);
      }
    } catch (error) {
      console.error(error);      
    }

    peerConnection.current.ontrack = ((event: RTCTrackEvent) => {
      event.track.onunmute = () => {
        if (peerMediaElements.current['remote']) {
          peerMediaElements.current['remote']!.srcObject = event.streams[0];
        }
      }
    });

    peerConnection.current.onconnectionstatechange = (e) => {
      if (peerConnection.current === null) return;
      const { connectionState } = peerConnection.current;
      const status = connectionState === 'connected' || connectionState === 'connecting'
        ? connectionState
        : connectionState === 'disconnected'
          ? 'connecting'
          : 'pending';

      if (status === 'connected') errorTextSet('');
      connectStatusSet(status)
    } 

    peerConnection.current.onnegotiationneeded = async () => {
      try {
        await peerConnection.current!.setLocalDescription();
        consultationSocket.emit(ACTIONS_CONSULTATIONS.CONSULTANT_SEND_OFFER, {
          jwt: PersistanceService.getToken(),
          consultationUuid,
          offer: peerConnection.current!.localDescription,
        });
      } catch (error) {
        console.log(error);
      }
    };

    peerConnection.current.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      consultationSocket.emit(ACTIONS_CONSULTATIONS.RELAY_ICE, {
        iceCandidate: event.candidate,
        clientId: clientIdCaller,
      });
    };
  }, [
    consultationUuid,
    localMediaStream,
    peerConnection,
    peerMediaElements,
    consultationCurrentSet,
  ]);

  const callStop = React.useCallback(() => {
    mediaStop();
    navigate('/consultation-list');
    consultationSocket.emit(ACTIONS_CONSULTATIONS.CONSULTANT_LEAVE)
  }, [
    navigate,
    mediaStop,
  ]);

  const callerLeave = React.useCallback(() => {
    errorTextSet('Собеседник покинул беседу');
    connectStatusSet('pending');
  }, [
  ]);

  const callerDisconnect = React.useCallback(() => {
    errorTextSet('Потеряна связь с собеседником');
    connectStatusSet('connecting');
  }, [
  ]);

  const iceCandidateSet = React.useCallback((event: { iceCandidate: RTCIceCandidate }) => {
    try {
      peerConnection.current!.addIceCandidate(event.iceCandidate);
    } catch (error) {
      console.log(error);
    }
  }, [
    peerConnection,
  ]);

  const answerSet = React.useCallback(async (event: { answer: RTCSessionDescriptionInit }) => {
    if (!peerConnection.current) {
      return console.warn('answerSet - peerConnection not exist');
    }
    
    await peerConnection.current.setRemoteDescription(
      new RTCSessionDescription(event.answer),
    );
  }, [
    peerConnection,
  ]);

  const cameraToggleAndSend = React.useCallback(() => {
    consultationSocket.emit(ACTIONS_CONSULTATIONS.CONSULTANT_DEVICES_SET, {
      consultationUuid,
      cameraMuted: !cameraMuted,
      microphoneMuted,
    });
    cameraToggle();
  }, [
    consultationUuid,
    cameraMuted,
    microphoneMuted,
    cameraToggle,
  ]);

  const microphoneToggleAndSend = React.useCallback(() => {
    consultationSocket.emit(ACTIONS_CONSULTATIONS.CONSULTANT_DEVICES_SET, {
      consultationUuid,
      cameraMuted,
      microphoneMuted: !microphoneMuted,
    });
    microphoneToggle();
  }, [
    consultationUuid,
    cameraMuted,
    microphoneMuted,
    microphoneToggle,
  ]);

  const callerDevicesSet = React.useCallback((event: { cameraMuted: boolean; microphoneMuted: boolean; }) => {
    remoteCameraMutedSet(event.cameraMuted);
    remoteMicrophoneMutedSet(event.microphoneMuted);
  }, []);

  React.useEffect(() => {
    if (connectStatus === 'connected') {
      consultationSocket.emit(ACTIONS_CONSULTATIONS.CONSULTANT_DEVICES_SET, {
        consultationUuid,
        cameraMuted,
        microphoneMuted,
      });
    }
  }, [
    connectStatus,
    consultationUuid,
    cameraMuted,
    microphoneMuted,
  ]);

  React.useEffect(() => {
    consultantConnect();

    return () => {
      consultationSocket.emit(ACTIONS_CONSULTATIONS.CONSULTANT_LEAVE, { consultationUuid });
      mediaStop();
    }
  }, [
    consultationUuid,
    consultantConnect,
    callStop,
    mediaStop,
  ]);

  return React.useMemo(() => ({
    connectStatus,
    remoteCameraMuted,
    remoteMicrophoneMuted,
    remoteName,
    errorText,
    callStart,
    callStop,
    iceCandidateSet,
    answerSet,
    cameraToggleAndSend,
    microphoneToggleAndSend,
    callerDevicesSet,
    callerLeave,
    callerDisconnect,
  }), [
    connectStatus,
    remoteCameraMuted,
    remoteMicrophoneMuted,
    remoteName,
    errorText,
    callStart,
    callStop,
    iceCandidateSet,
    answerSet,
    cameraToggleAndSend,
    microphoneToggleAndSend,
    callerDevicesSet,
    callerLeave,
    callerDisconnect,
  ]);
};

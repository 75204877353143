import React from 'react';

import { useAppStore } from '@app/stores';
import { ConsultationType } from '@app/types';
import { ConsultationApi } from '@app/api';


export interface MainContextInterface {
  consultationList: ConsultationType[];
  consultationListIsLoading: boolean;
  consultationSelected: ConsultationType | null;
  noteModalOpen: (consultation: ConsultationType) => void;
  noteModalClose: () => void;
  consultationUpdate: (consultation: ConsultationType) => void;
  consultationClose: (id: number) => Promise<void>;
}

export const useMainHook = (
): MainContextInterface => {
  const { notifyCall } = useAppStore();
  const [ consultationList, consultationListSet ] = React.useState<ConsultationType[]>([]);
  const [ consultationListIsLoading, consultationListIsLoadingSet ] = React.useState(false);
  const [ consultationSelected, consultationSelectedSet ] = React.useState<ConsultationType | null>(null);
  
  const noteModalOpen = React.useCallback((call: ConsultationType) => consultationSelectedSet(call), []);
  const noteModalClose = React.useCallback(() => consultationSelectedSet(null), []);

  const consultationUpdate = React.useCallback((consultation: ConsultationType) => {
    consultationListSet((state) => state.map((item) => consultation.id === item.id ? consultation : item));
  }, []);

  const consultationClose = React.useCallback(async (id: number) => {
    consultationListIsLoadingSet(true);
    const response = await ConsultationApi.close({ id });
    consultationListIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Не удалось закрыть консультацию',
      });
    }

    consultationListSet((state) => {
      return state.filter((item) => item.id !== id);
    });
  }, [
    notifyCall,
  ]);

  const consultationListDownload = React.useCallback(async () => {
    consultationListIsLoadingSet(true);
    const response = await ConsultationApi.listInFlightByUser();
    consultationListIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке консультаций.'
      });
    }

    consultationListSet(response.data.consultationList);
  }, [
    notifyCall,
  ]);

  React.useEffect(() => {
    consultationListDownload();
  }, [
    consultationListDownload,
  ]);

  return React.useMemo(() => ({
    consultationList,
    consultationListIsLoading,
    consultationSelected,
    noteModalOpen,
    noteModalClose,
    consultationUpdate,
    consultationClose,
  }), [
    consultationList,
    consultationListIsLoading,
    consultationSelected,
    noteModalOpen,
    noteModalClose,
    consultationUpdate,
    consultationClose,
  ]);
};

import {
  ButtonCancelBlock,
  ButtonEditBlock,
  CardDataComposition,
} from '@app/components';

import { useMainContext } from '../../context';


export const UserAccessComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    oneIsLoading,
    userAccessIsEditing,
    userAccessFormOpen,
    userAccessFormClose,
  } = useMainContext();

  return (
    <CardDataComposition
      title="Матрица доступа к настройкам пользователя"
      extra={
        <>
          {!userAccessIsEditing && <ButtonEditBlock onClick={() => userAccessFormOpen()} />}
          {userAccessIsEditing && <ButtonCancelBlock onClick={() => userAccessFormClose()} buttonIcon />}
        </>
      }
      loading={oneIsLoading}
      mb12
    >
      {children}
    </CardDataComposition>
  );
}

import React from 'react';

import { ACTIONS_CALL, callSocket } from '@app/api';
import { PersistanceService } from '@app/services';

import { useCallContext } from '../call';
import { useMainContext } from '../main';


export interface ConnectionContextInterface {
}

export const useConnectionHook = (
): ConnectionContextInterface => {
  const { callUuid } = useMainContext();

  const {
    operatorReady,
    callStart,
    iceCandidateSet,
    answerSet,
    callerCallStop,
    callerDevicesSet,
    callerLeave,
    callerDisconnect,
  } = useCallContext();

  React.useEffect(() => {
    if (operatorReady) {
      callSocket.emit(ACTIONS_CALL.OPERATOR_READY, {
        jwt: PersistanceService.getToken(),
        callUuid,
      });
    }
    
    return () => {
      if (operatorReady) callSocket.emit(ACTIONS_CALL.OPERATOR_LEAVE);
    }
  }, [
    callUuid,
    operatorReady,
  ]);

  // React.useEffect(() => {
  //   callSocket.emit(ACTIONS_CALL.OPERATOR_CONNECT, { jwt: PersistanceService.getToken() });
  //   return () => {callSocket.emit(ACTIONS_CALL.OPERATOR_DISCONNECT);};
  // }, []);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.ICE_CANDIDATE, iceCandidateSet);
    return () => {callSocket.off(ACTIONS_CALL.ICE_CANDIDATE, iceCandidateSet)};
  }, [iceCandidateSet]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CALL_READY, callStart);
    return () => {callSocket.off(ACTIONS_CALL.CALL_READY, callStart)};
  }, [callStart]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.ANSWER_TO_OPERATOR_SEND, answerSet);
    return () => {callSocket.off(ACTIONS_CALL.ANSWER_TO_OPERATOR_SEND, answerSet)};
  }, [answerSet]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CALLER_CALL_STOP, callerCallStop);
    return () => {callSocket.off(ACTIONS_CALL.CALLER_CALL_STOP, callerCallStop)};
  }, [callerCallStop]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CALLER_DEVICES_SET, callerDevicesSet);
    return () => {callSocket.off(ACTIONS_CALL.CALLER_DEVICES_SET, callerDevicesSet)};
  }, [callerDevicesSet]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CALLER_DISCONNECT, callerDisconnect);
    return () => {callSocket.off(ACTIONS_CALL.CALLER_DISCONNECT, callerDisconnect)};
  }, [callerDisconnect]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CALLER_LEAVE, callerLeave);
    return () => {callSocket.off(ACTIONS_CALL.CALLER_LEAVE, callerLeave)};
  }, [callerLeave]);
  
  return React.useMemo(() => ({
  }), [
  ]);
}

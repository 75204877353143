import React from 'react';
import { RoleType } from '@app/types';
import { RoleApi } from '@app/api';
import { useAppStore } from '@app/stores';


export interface MainContextInterface {
  list: RoleType[];
  listIsLoading: boolean;
};

export const useMainHook = (
): MainContextInterface => {
  const { notifyCall } = useAppStore();
  const [ list, listSet ] = React.useState<RoleType[]>([]);
  const [ listIsLoading, listIsLoadingSet ] = React.useState(false);

  const listDownload = React.useCallback(async () => {
    listIsLoadingSet(true);
    const response = await RoleApi.list();
    listIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке ролей.'
      });
    }

    listSet(response.data.list);
  }, [
    notifyCall,
  ]);

  React.useEffect(() => {
    listDownload();
  }, [
    listDownload,
  ]);

  return React.useMemo(() => ({
    list,
    listIsLoading,
  }), [
    list,
    listIsLoading,
  ]);
};

import React from 'react';

import {
  ButtonBackBlock,
  ButtonSearchBlock,
  CardDataComposition,
  NoteSmart,
} from '@app/components';

import {
  FilterProvider,
  useMainContext,
} from '../../context';

import { FilterComponent } from '../filter';
import { ListComponent } from '../list';


export const MainComponent = () => {
  const {
    list,
    listIsLoading,
    filterOpened,
    filterCount,
    callSelected,
    filterOpen,
    filterClose,
    callModalClose,
    callUpdate,
  } = useMainContext();

  const entityId = (callSelected && callSelected.id) || null;
  const title = String(callSelected?.id);
  const note = callSelected?.note || '';
  const backHandler = filterOpened ? filterClose : undefined;

  const cardBody = filterOpened ? (
    <FilterProvider>
      <FilterComponent />
    </FilterProvider>
  ) : (
    <ListComponent />
  );

  return (
    <CardDataComposition
      title="Пропущенные звонки"
      noData={list.length === 0 && !filterOpened}
      loading={listIsLoading}
      extra={
        <React.Fragment>
          {!filterOpened && <ButtonSearchBlock onClick={filterOpen} buttonIcon count={filterCount}/>}
          <ButtonBackBlock buttonIcon onClick={backHandler} />
        </React.Fragment>
      }
    >
      {cardBody}

      <NoteSmart
        entityId={entityId}
        type="call"
        title={title}
        note={note}
        closeModal={callModalClose}
        entityUpdate={callUpdate}
      />
    </CardDataComposition>
  );
};

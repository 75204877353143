import {
  ButtonConfirmComposition,
  ButtonLoadingBrick,
  ButtonsBrick,
  CardDataComposition,
  QueueNextIcon,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';

import { useMainContext } from '../../context';
import React from 'react';


export const MainComponent = () => {
  const {
    callInFlight,
    callListLen,
    callList,
    operatorListLen,
    callStartLoading,
    callStartByUuidLoading,
    callStart,
    callReject,
    callInFlightStart
  } = useMainContext();

  return (
    <CardDataComposition
      title={`Операторов в сети: ${operatorListLen || 0}, Человек в очереди: ${callListLen || 0}`}
      extra={
        <React.Fragment>
          {callInFlight ? (
            <ButtonLoadingBrick
              variant="contained"
              onClick={callInFlightStart}
              startIcon={<QueueNextIcon />}
              loading={!!callStartByUuidLoading}
            >
              Продолжить прием
            </ButtonLoadingBrick>
          ): (
            <ButtonLoadingBrick
              disabled={callListLen === 0 || callStartByUuidLoading !== null}
              variant="contained"
              onClick={() => callStart()}
              startIcon={<QueueNextIcon />}
              loading={callStartLoading}
            >
              Начать прием
            </ButtonLoadingBrick>
          )}
        </React.Fragment>
      }
      loading={false}
      noData={callList.length === 0 && !callInFlight}
    >
      {!callInFlight && (
        <TableContainerBrick>
          <TableBrick aria-label="roles">
            <TableHeadBrick>
              <TableRowBrick>
                <TableCellBrick>Очередь</TableCellBrick>
                <TableCellBrick>Фамилия</TableCellBrick>
                <TableCellBrick>Имя</TableCellBrick>
                <TableCellBrick>Отчество</TableCellBrick>
                <TableCellBrick>Телефон</TableCellBrick>
                <TableCellBrick>СНИЛС</TableCellBrick>
                <TableCellBrick></TableCellBrick>
              </TableRowBrick>
            </TableHeadBrick>
            <TableBodyBrick>
              {callList.map((item, index) => (
                <TableRowBrick
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={item.uuid}
                >
                  <TableCellBrick component="th" scope="row">
                    {index + 1}
                  </TableCellBrick>
                  <TableCellBrick>{item.lastName}</TableCellBrick>
                  <TableCellBrick>{item.firstName}</TableCellBrick>
                  <TableCellBrick>{item.secondName}</TableCellBrick>
                  <TableCellBrick>{item.phone}</TableCellBrick>
                  <TableCellBrick>{item.snils}</TableCellBrick>
                  <TableCellBrick>
                    <ButtonsBrick>
                      <ButtonLoadingBrick
                        size="small"
                        onClick={() => callStart(item.uuid)}
                        disabled={!!callStartByUuidLoading || callStartLoading || !!callInFlight}
                        loading={callStartByUuidLoading === item.uuid}
                      >
                        Принять без очереди
                      </ButtonLoadingBrick>
                      <ButtonConfirmComposition
                        onClick={() => callReject(item.id)}
                        message="Текущий звонок будет удален из очереди."
                        size="small"
                        type="button"
                        buttonText="Отклонить"
                        disabled={!!callStartByUuidLoading || callStartLoading || !!callInFlight}
                      />
                    </ButtonsBrick>
                    </TableCellBrick>
                </TableRowBrick>
              ))}
            </TableBodyBrick>
          </TableBrick>
        </TableContainerBrick>
      )}
    </CardDataComposition>
  );
};

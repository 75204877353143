import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  WidgetLinkBrick,
  ManageAccountsIcon,
  WidgetsListBlock,
  PhoneCallbackIcon,
  PhoneSettingsIcon,
  CallListIcon,
  FormatListBulletedIcon,
  VerifiedIcon,
  GroupIcon,
  AdminGuardIcon,
} from '@app/components';

import { useAuthStore } from '@app/stores';


export const HomeFeature = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const role = user === null ? null : user.role;

  if (role === null) return null;

  const rolesView = role.ROLE_CREATE === 'ALL' || role.ROLE_UPDATE === 'ALL' || role.ROLE_READ === 'ALL';
  const usersView = role.USER_CREATE || role.USER_UPDATE || role.USER_READ;
  const licenseView = role.LICENSE_ACCESS === 'ALL';
  const callsView = role.CALL_QUEUE_ACCESS === 'ALL';
  const callsMissedView = role.CALL_LIST_MISSED_ACCESS === 'ALL';
  const callsGeneralView = role.CALL_LIST_GENERAL_ACCESS === 'ALL';
  const consultationsView = role.CONSULTATION_ACCESS === 'ALL'; 
  const consultationsGeneralView = role.CONSULTATION_LIST_GENERAL_ACCESS === 'ALL'; 

  return (
    <React.Fragment>
      <WidgetsListBlock
        title="Система"
      >
        {rolesView && (
          <WidgetLinkBrick
            title="Роли"
            onClick={() => navigate('/role-list')}
            icon={<AdminGuardIcon />}
          />
        )}
        {usersView && (
          <WidgetLinkBrick
            title="Пользователи"
            onClick={() => navigate('/user-list')}
            icon={<ManageAccountsIcon />}
          />
        )}
        {licenseView && (
          <WidgetLinkBrick
            title="Лицензия"
            onClick={() => navigate('/license')}
            icon={<VerifiedIcon />}
          />
        )}
      </WidgetsListBlock>
      <WidgetsListBlock
        title="Звонки"
      >
        {callsView && (
          <WidgetLinkBrick
            title="Звонки"
            onClick={() => navigate('/call-list-queue')}
            icon={<PhoneCallbackIcon />}
          />
        )}
        {callsView && (
          <WidgetLinkBrick
            title="Журнал звонков"
            onClick={() => navigate('/call-list-my')}
            icon={<PhoneSettingsIcon />}
          />
        )}
        {callsMissedView && (
          <WidgetLinkBrick
            title="Пропущенные звонки"
            onClick={() => navigate('/call-list-missed')}
            icon={<CallListIcon />}
          />
        )}
        {callsGeneralView && (
          <WidgetLinkBrick
            title="Общий журнал звонков"
            onClick={() => navigate('/call-list-general')}
            icon={<FormatListBulletedIcon />}
          />
        )}
      </WidgetsListBlock>
      <WidgetsListBlock
        title="Консультации"
      >
        {consultationsView && (
          <WidgetLinkBrick
            title="Консультации"
            onClick={() => navigate('/consultation-list')}
            icon={<GroupIcon />}
          />
        )}
        {consultationsView && (
          <WidgetLinkBrick
            title="Журнал консультаций"
            onClick={() => navigate('/consultation-list-my')}
            icon={<PhoneSettingsIcon />}
          />
        )}
        {consultationsGeneralView && (
          <WidgetLinkBrick
            title="Общий журнал консультаций"
            onClick={() => navigate('/consultation-list-general')}
            icon={<FormatListBulletedIcon />}
          />
        )}
      </WidgetsListBlock>
    </React.Fragment>
  );
}

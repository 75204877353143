import { MainComponent } from './components';
import { MainProvider } from './context';
import { NoteProps } from './note.props';


export const NoteSmart = ({
  entityId,
  type,
  title,
  note,
  closeModal,
  entityUpdate,
}: NoteProps) => {
  return (
    <MainProvider
      entityId={entityId}
      type={type}
      title={title}
      note={note}
      closeModal={closeModal}
      entityUpdate={entityUpdate}
    >
      <MainComponent />
    </MainProvider>
  );
};

import io from 'socket.io-client';

import { remoteHost } from 'config';


export const callSocket = io(`${remoteHost}/calls` || '', {
  // @ts-ignore: Unreachable code error
  'force new connection': true,
  reconnectionAttempts: ('Infinity' as unknown as number),
  timeout: 10000,
  transports: ['websocket'],
  // autoConnect: false,
});

// callSocket.connect();
import React from 'react';

import { GeneralUpdateProvider } from '../../context';
import { GeneralComponent } from '../general';
import { GeneralTableComponent } from '../general-table';
import { GeneralUpdateComponent } from '../general-update';
import { UserAccessComponent } from '../user-access';
import { UserAccessTableComponent } from '../user-access-table';
import { UserAccessUpdateComponent } from '../user-access-update';
import { AppAccessTableComponent } from '../app-access-table';
import { AppAccessComponent } from '../app-access';
import { AppAccessUpdateComponent } from '../app-access-update';


export const MainComponent = () => {
  return (
    <React.Fragment>
      <GeneralUpdateProvider>
        <GeneralComponent>
          <GeneralTableComponent />
          <GeneralUpdateComponent />
        </GeneralComponent>

        <UserAccessComponent>
          <UserAccessTableComponent />
          <UserAccessUpdateComponent />
        </UserAccessComponent>

        <AppAccessComponent>
          <AppAccessTableComponent />
          <AppAccessUpdateComponent />
        </AppAccessComponent>
      </GeneralUpdateProvider>
    </React.Fragment>
  );
}

export const ACTIONS_CONSULTATIONS = {
  // EMIT
  CONSULTANT_CONNECT: 'CONSULTANT_CONNECT',
  CONSULTANT_LEAVE: 'CONSULTANT_LEAVE',
  CONSULTANT_SEND_OFFER: 'CONSULTANT_SEND_OFFER',
  CONSULTANT_DEVICES_SET: 'CONSULTANT_DEVICES_SET',
  RELAY_ICE: 'RELAY_ICE',

  // LISTEN
  CALL_READY: 'CALL_READY',
  CALLER_DEVICES_SET: 'CALLER_DEVICES_SET',
  CALLER_LEAVE: 'CALLER_LEAVE',
  CALLER_DISCONNECT: 'CALLER_DISCONNECT',




  // LISTEN

  // EMIT
  OFFER_TO_CALLER_SEND: 'OFFER_TO_CALLER_SEND',
  ANSWER_TO_CONSULTANT_SEND: 'ANSWER_TO_CONSULTANT_SEND',

  ICE_CANDIDATE: 'ICE_CANDIDATE',
};
// import { Navigate } from 'react-router-dom';

// import { useAuthStore } from '@app/stores';


export const AdminGuardHoc = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  // const { user } = useAuthStore();

  // if (user === null || user.role !== 'admin') {
  //   return (<Navigate to='/' />);
  // }

  return (
    <>{children}</>
  );
}

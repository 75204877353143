import {
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';

import {
  translateDateHelper,
} from '@app/helpers';

import { useMainContext } from '../../context';


export const GeneralTableComponent = () => {
  const {
    one,
    generalIsEditing,
  } = useMainContext();
  if (one === null || generalIsEditing) return null;

  return (
    <TableContainerBrick>
      <TableBrick aria-label="users">
        <TableHeadBrick>
          <TableRowBrick>
            <TableCellBrick>ID</TableCellBrick>
            <TableCellBrick>Название</TableCellBrick>
            <TableCellBrick>Дата создания</TableCellBrick>
            <TableCellBrick>Дата обновления</TableCellBrick>
          </TableRowBrick>
        </TableHeadBrick>
        <TableBodyBrick>
          <TableRowBrick
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            key={one.id}
          >
            <TableCellBrick component="th" scope="row">
              {one.id}
            </TableCellBrick>
            <TableCellBrick>{one.name}</TableCellBrick>
            <TableCellBrick>{translateDateHelper(one.createdAt)}</TableCellBrick>
            <TableCellBrick>{translateDateHelper(one.updatedAt)}</TableCellBrick>
          </TableRowBrick>
        </TableBodyBrick>
      </TableBrick>
    </TableContainerBrick>
  );
};

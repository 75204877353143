import React from 'react';

import { consultationSocket } from '@app/api';
import { ACTIONS_CONSULTATIONS } from '@app/api';

import { useCallContext } from '../call';


export interface ConnectionContextInterface {
};

export const useConnectionHook = (
): ConnectionContextInterface => {
  const {
    callStart,
    iceCandidateSet,
    answerSet,
    callerDevicesSet,
    callerDisconnect,
    callerLeave,
  } = useCallContext();

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.ICE_CANDIDATE, iceCandidateSet);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.ICE_CANDIDATE, iceCandidateSet)};
  }, [iceCandidateSet]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.CALL_READY, callStart);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.CALL_READY, callStart)};
  }, [callStart]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.ANSWER_TO_CONSULTANT_SEND, answerSet);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.ANSWER_TO_CONSULTANT_SEND, answerSet)};
  }, [answerSet]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.CALLER_DEVICES_SET, callerDevicesSet);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.CALLER_DEVICES_SET, callerDevicesSet)};
  }, [callerDevicesSet]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.CALLER_DISCONNECT, callerDisconnect);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.CALLER_DISCONNECT, callerDisconnect)};
  }, [callerDisconnect]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.CALLER_LEAVE, callerLeave);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.CALLER_LEAVE, callerLeave)};
  }, [callerLeave]);
  
  return React.useMemo(() => ({
  }), [
  ]);
};

import { useParams } from 'react-router-dom';

import {
  PageBaseLayout,
  RoleOneFeature,
} from '@app/components'


export const RoleOnePage = () => {
  const { id } = useParams();
  return (
    <PageBaseLayout
      breadcrumbs={[
        { title: 'Главная', link: '/' },
        { title: 'Роли', link: '/role-list' },
        { title: 'Роль №' + id, link: '/' },
      ]}
    >
      <RoleOneFeature />
    </PageBaseLayout>
  );
};

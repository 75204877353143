import React from 'react';

import { ConsultationType } from '@app/types';
import { useAppStore } from '@app/stores';
import { ConsultationApi } from '@app/api';


export interface MainContextInterface {
  list: ConsultationType[];
  listIsLoading: boolean;
  page: number,
  pages: number,
  filterOpened: boolean;
  filterPayload: FilterPayloadType | null;
  filterCount: number;
  callSelected: ConsultationType | null;
  pageSelect: (page: number) => void;
  filterOpen: () => void;
  filterClose: () => void;
  listDownload: (payload?: FilterPayloadType) => void;
  filterReset: () => void;
  callModalOpen: (call: ConsultationType) => void;
  callModalClose: () => void;
  callUpdate: (call: ConsultationType) => void;
};

type FilterPayloadType = {
  [ket: string]: any;
}

export const useMainHook = (
): MainContextInterface => {
  const { notifyCall } = useAppStore();
  const [ list, listSet ] = React.useState<ConsultationType[]>([]);
  const [ listIsLoading, listIsLoadingSet ] = React.useState(false);
  const [ page, pageSet ] = React.useState(1);
  const [ pages, pagesSet ] = React.useState(1);
  const [ filterOpened, filterOpenedSet ] = React.useState(false);
  const [ filterPayload, filterPayloadSet ] = React.useState<FilterPayloadType | null>(null);
  const [ callSelected, callSelectedSet ] = React.useState<ConsultationType | null>(null);

  const filterCount = React.useMemo(() => {
    if (!filterPayload || filterPayload === null) return 0;

    const keys = Object.keys(filterPayload).filter((key) => filterPayload[key]);
    return keys.length;
  }, [
    filterPayload,
  ]);

  const pageSelect = React.useCallback((page: number) => pageSet(page), []);
  const filterOpen = React.useCallback(() => filterOpenedSet(true), []);
  const filterClose = React.useCallback(() => filterOpenedSet(false), []);
  const callModalOpen = React.useCallback((call: ConsultationType) => callSelectedSet(call), []);
  const callModalClose = React.useCallback(() => callSelectedSet(null), []);

  const listDownload = React.useCallback(async (filterPayloadNew?: FilterPayloadType) => {
    if (filterPayloadNew) filterPayloadSet(filterPayloadNew);
    const payload = filterPayloadNew ? filterPayloadNew : filterPayload
    
    listIsLoadingSet(true);
    const response = await ConsultationApi.listGeneral({ page: filterPayloadNew ? 1 : page, ...payload });
    listIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке журнала',
      });
    }

    pagesSet(response.data.pages);
    listSet(response.data.list);
  }, [
    page,
    filterPayload,
    notifyCall,
  ]);

  const filterReset = React.useCallback(() => {
    filterPayloadSet(null);
    filterClose();
    listDownload();
  }, [
    filterClose,
    listDownload,
  ]);

  const callUpdate = React.useCallback((call: ConsultationType) => {
    listSet((state) => state.map((item) => call.id === item.id ? call : item));
  }, []);

  React.useEffect(() => {
    listDownload();
  }, [
    listDownload,
  ]);

  return React.useMemo(() => ({
    list,
    listIsLoading,
    page,
    pages,
    filterOpened,
    filterPayload,
    filterCount,
    callSelected,
    pageSelect,
    filterOpen,
    filterClose,
    listDownload,
    filterReset,
    callModalOpen,
    callModalClose,
    callUpdate,
  }), [
    list,
    listIsLoading,
    page,
    pages,
    filterOpened,
    filterPayload,
    filterCount,
    callSelected,
    pageSelect,
    filterOpen,
    filterClose,
    listDownload,
    filterReset,
    callModalOpen,
    callModalClose,
    callUpdate,
  ]);
};

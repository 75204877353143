import { MainComponent } from './components';
import { MainProvider, ConnectionProvider } from './context';


export const CallListQueueFeature = () => {
  return (
    <MainProvider>
      <ConnectionProvider>
        <MainComponent />
      </ConnectionProvider>
    </MainProvider>
  );
}

export const ACTIONS_CALL = {
  // LISTEN
  DATA_GENERAL_SEND: 'DATA_GENERAL_SEND',
  CALLER_LIST_TO_OPERATOR_SEND: 'CALLER_LIST_TO_OPERATOR_SEND',
  CALL_READY: 'CALL_READY', 
  ANSWER_TO_OPERATOR_SEND: 'ANSWER_TO_OPERATOR_SEND',
  ICE_CANDIDATE: 'ICE_CANDIDATE',
  CALLER_CALL_STOP: 'CALLER_CALL_STOP',
  CALLER_DEVICES_SET: 'CALLER_DEVICES_SET',
  CALLER_LEAVE: 'CALLER_LEAVE',
  CALLER_DISCONNECT: 'CALLER_DISCONNECT',

  
  DATA_TO_CALLER_SEND: 'DATA_TO_CALLER_SEND',
  CALLER_TO_OPERATOR_SEND: 'CALLER_TO_OPERATOR_SEND',
  CLIENT_DISCONNECT: 'CLIENT_DISCONNECT',

  // EMIT
  OPERATOR_CONNECT: 'OPERATOR_CONNECT',
  OPERATOR_READY: 'OPERATOR_READY',
  OPERATOR_LEAVE: 'OPERATOR_LEAVE',
  OPERATOR_SEND_OFFER: 'OPERATOR_SEND_OFFER',
  OPERATOR_CALL_STOP: 'OPERATOR_CALL_STOP',
  OPERATOR_DEVICES_SET: 'OPERATOR_DEVICES_SET',
  RELAY_ICE: 'RELAY_ICE',


  OPERATOR_DISCONNECT: 'OPERATOR_DISCONNECT',
  OPERATOR_CALL_START: 'OPERATOR_CALL_START',
  OPERATOR_CALL_REJECT: 'OPERATOR_CALL_REJECT',
};

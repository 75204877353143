import React from 'react';
import { useNavigate } from 'react-router-dom';

import { RoleApi, UserApi } from '@app/api';
import { translateErrorHelper } from '@app/helpers';
import { useAppStore } from '@app/stores';

import {
  BackendErrorsType,
  ChangeFieldEventType,
  HandbkItemType,
} from '@app/types';


const initialFormValue = {
  email: '',
  firstName: '',
  lastName: '',
  secondName: '',
  password: '',
  roleId: null as unknown as number,
};

export interface MainContextInterface {
  formValue: typeof initialFormValue;
  formDirty: boolean;
  formIsSubmitting: boolean;
  roleList: HandbkItemType[];
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
};

export const useMainHook = (
): MainContextInterface => {
  const navigate = useNavigate();
  const { notifyCall } = useAppStore();
  const [ formValue, formValueSet ] = React.useState(initialFormValue);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSubmitting, formIsSubmittingSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);
  const [ roleList, roleListSet ] = React.useState<HandbkItemType[]>([]);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    console.log(e)
    formValueSet((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    formIsSubmittingSet(true);
    const response = await UserApi.create(formValue);
    formIsSubmittingSet(false);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось создать пользователя.',
      });

      errorsSet(response.errors as BackendErrorsType);
    }

    notifyCall({
      type: 'success',
      message: 'Пользователь успешно создан.'
    });
    navigate(`/user-list/${response.data.user.id}`);
  }, [
    formValue,
    notifyCall,
    navigate,
  ]);

  const errorSet = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && translateErrorHelper(errors[fieldName][0]);
  }, [
    errors,
  ]);

  const roleListLoad = React.useCallback(async () => {
    const response = await RoleApi.handbk();

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Не удалось загрузить роли',
      });
    }

    roleListSet(response.data.handbk.items)
  }, [
    notifyCall,
  ]);

  React.useEffect(() => {
    roleListLoad();
  }, [
    roleListLoad,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    roleList,
    changeFormValue,
    onSubmit,
    errorSet,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    roleList,
    changeFormValue,
    onSubmit,
    errorSet,
  ]);
};

import { ConsultationListGeneralFeature, PageBaseLayout } from '@app/components'


export const ConsultationListGeneralPage = () => {
  return (
    <PageBaseLayout breadcrumbs={[
      { title: 'Главная', link: '/' },
      { title: 'Общий журнал консультаций', link: '/' },
    ]}>
      <ConsultationListGeneralFeature />
    </PageBaseLayout>
  );
};

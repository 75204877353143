import { MainComponent } from './components';

import {
  CallProvider,
  ConnectionProvider,
  DevicesProvider,
  MainProvider,
  NoteProvider,
} from './context';


export const CallOneFeature = () => {
  return (
    <MainProvider>
      <DevicesProvider>
        <NoteProvider>
          <CallProvider>
            <ConnectionProvider>
              <MainComponent />
            </ConnectionProvider>
          </CallProvider>
        </NoteProvider>
      </DevicesProvider>
    </MainProvider>
  );
}

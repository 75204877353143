import { HttpService } from '@app/services';
import { BaseResponseInterface, HandbkInterface } from '@app/types';

import {
  RoleCreateRequest,
  RoleCreateResponse,
  RoleListResponse,
  RoleOneRequest,
  RoleOneResponse,
  RoleUpdateRequest,
  RoleUpdateResponse,
} from './types';


export class RoleApi {
  public static async create(
    payload: RoleCreateRequest,
  ): Promise<BaseResponseInterface<RoleCreateResponse>> {
    return await HttpService.sendRequest('POST', '/roles/create', payload);
  }

  public static async list(
  ): Promise<BaseResponseInterface<RoleListResponse>> {
    return await HttpService.sendRequest('GET', '/roles/list');
  }

  public static async one(
    payload: RoleOneRequest,
  ): Promise<BaseResponseInterface<RoleOneResponse>> {
    return await HttpService.sendRequest('GET', '/roles/one', payload);
  }

  public static async update(
    payload: RoleUpdateRequest,
  ): Promise<BaseResponseInterface<RoleUpdateResponse>> {
    return await HttpService.sendRequest('POST', '/roles/update', payload);
  }

  public static async handbk(
  ): Promise<BaseResponseInterface<{ handbk: HandbkInterface }>> {
    return await HttpService.sendRequest('GET', '/roles/handbk');
  }
}

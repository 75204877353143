import {
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';

import { tranlsateRoleAccessHelper } from '@app/helpers';

import { useMainContext } from '../../context';


export const AppAccessTableComponent = () => {
  const {
    one,
    appAccessIsEditing,
  } = useMainContext();
  if (one === null || appAccessIsEditing) return null;

  return (
    <TableContainerBrick>
      <TableBrick>
        <TableHeadBrick>
          <TableRowBrick>
            <TableCellBrick>Лицензия</TableCellBrick>
            <TableCellBrick>Очередь звонков</TableCellBrick>
            <TableCellBrick>Список пропущенных</TableCellBrick>
            <TableCellBrick>Общий журнал звонков</TableCellBrick>
            <TableCellBrick>Консультации</TableCellBrick>
            <TableCellBrick>Общий журнал консультаций</TableCellBrick>
          </TableRowBrick>
        </TableHeadBrick>
        <TableBodyBrick>
          <TableRowBrick
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCellBrick>{tranlsateRoleAccessHelper(one.LICENSE_ACCESS)}</TableCellBrick>
            <TableCellBrick>{tranlsateRoleAccessHelper(one.CALL_QUEUE_ACCESS)}</TableCellBrick>
            <TableCellBrick>{tranlsateRoleAccessHelper(one.CALL_LIST_MISSED_ACCESS)}</TableCellBrick>
            <TableCellBrick>{tranlsateRoleAccessHelper(one.CALL_LIST_GENERAL_ACCESS)}</TableCellBrick>
            <TableCellBrick>{tranlsateRoleAccessHelper(one.CONSULTATION_ACCESS)}</TableCellBrick>
            <TableCellBrick>{tranlsateRoleAccessHelper(one.CONSULTATION_LIST_GENERAL_ACCESS)}</TableCellBrick>
          </TableRowBrick>
        </TableBodyBrick>
      </TableBrick>
    </TableContainerBrick>
  );
};

import React from 'react';

import {
  GeneralUpdateContextInterface,
  useGeneralUpdateHook,
} from './general-update.hook';

const UpdateContext = React.createContext<GeneralUpdateContextInterface | undefined>(undefined);

export const useGeneralUpdateContext = () => {
  const context = React.useContext(UpdateContext);

  if (context === undefined) {
    throw new Error('useUpdateContext must be used within UpdateProvider');
  }

  return context;
}

export const GeneralUpdateProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useGeneralUpdateHook();

  return (
    <UpdateContext.Provider value={api}>
      {children}
    </UpdateContext.Provider>
  );
};

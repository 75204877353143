import React from 'react';

import { callSocket, ACTIONS_CALL } from '@app/api';
import { PersistanceService } from '@app/services';

import { useMainContext } from '../main';


export interface ConnectionContextInterface {
};

export const useConnectionHook = (
): ConnectionContextInterface => {
  const {
    dataGeneralSet,
    callListUpdate,
  } = useMainContext();

  React.useEffect(() => {
    callSocket.emit(ACTIONS_CALL.OPERATOR_CONNECT, { jwt: PersistanceService.getToken() });
    return () => {callSocket.emit(ACTIONS_CALL.OPERATOR_DISCONNECT);};
  }, []);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.DATA_GENERAL_SEND, dataGeneralSet);
    return () => {callSocket.off(ACTIONS_CALL.DATA_GENERAL_SEND, dataGeneralSet)};
  }, [dataGeneralSet]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CALLER_LIST_TO_OPERATOR_SEND, callListUpdate);
    return () => {callSocket.off(ACTIONS_CALL.CALLER_LIST_TO_OPERATOR_SEND, callListUpdate)};
  }, [callListUpdate]);
  
  return React.useMemo(() => ({
  }), [
  ]);
};

import React from 'react';

import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import { ProgressCircularFullpageBlock } from '@app/components';
import { useAppStore } from '@app/stores';
import { CallApi } from '@app/api';

import {
  MainContextInterface,
  useMainHook,
} from './main.hook';

const MainContext = React.createContext<MainContextInterface | undefined>(undefined);

export const useMainContext = () => {
  const context = React.useContext(MainContext);

  if (context === undefined) {
    throw new Error('useMainContext must be used within MainProvider');
  }

  return context;
}

export const MainProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useMainHook();
  const { uuid: callUuid } = useParams();
  const navigate = useNavigate();
  const { notifyCall } = useAppStore();
  const [loading, loadingSet] = React.useState(true);


  const checkActive = React.useCallback(async () => {
    if (callUuid === undefined) return navigate('/call-list-queue');

    loadingSet(true);
    const response = await CallApi.checkActive({ callUuid });

    if (response.success && response.data.active) {
      loadingSet(false);
      return;
    }

    notifyCall({
      type: 'error',
      message: 'Данная комната не активна или не существует',
    });

    navigate('/call-list-queue');
  }, [
    callUuid,
    notifyCall,
    navigate,
  ]);

  React.useEffect(() => {
    checkActive();
  }, [
    checkActive,
  ]);
  
  if (loading) return <ProgressCircularFullpageBlock />

  return (
    <MainContext.Provider value={api}>
      {children}
    </MainContext.Provider>
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACTIONS_CALL,
  callSocket,
} from '@app/api';

import { PersistanceService } from '@app/services';
import { CallConnectStatusType, CallType } from '@app/types';
import { useAppStore } from '@app/stores';

import { useMainContext } from '../main';
import { useDevicesContext } from '../devices';
import { useNoteContext } from '../note';


export interface CallContextInterface {
  connectStatus: CallConnectStatusType;
  operatorReady: boolean;
  remoteCameraMuted: boolean;
  remoteMicrophoneMuted: boolean;
  remoteName: string | undefined;
  errorText: string;
  callStart: (e: { call: CallType, clientIdCaller: string }) => Promise<void>;
  operatorCallStop: () => void;
  callerCallStop: () => void;
  iceCandidateSet: (event: { iceCandidate: RTCIceCandidate }) => void;
  answerSet: (event: { answer: RTCSessionDescriptionInit }) => void;
  cameraToggleAndSend: () => void;
  microphoneToggleAndSend: () => void;
  callerDevicesSet: (event: { cameraMuted: boolean; microphoneMuted: boolean; }) => void;
  callerLeave: () => void;
  callerDisconnect: () => void;
};

export const useCallHook = (
): CallContextInterface => {
  const {
    callUuid,
    localMediaStream,
    peerMediaElements,
    peerConnection,
    callCurrent,
    callCurrentSet,
  } = useMainContext();
  
  const {
    cameraMuted,
    microphoneMuted,
    mediaStart,
    mediaStop,
    cameraToggle,
    microphoneToggle,
  } = useDevicesContext();

  const { noteSave } = useNoteContext()

  const navigate = useNavigate();
  const { notifyCall } = useAppStore();
  const [connectStatus, connectStatusSet] = React.useState<CallConnectStatusType>('pending');
  const [operatorReady, operatorReadySet] = React.useState(false);
  const [remoteMicrophoneMuted, remoteMicrophoneMutedSet] = React.useState(false);
  const [remoteCameraMuted, remoteCameraMutedSet] = React.useState(false);
  const [errorText, errorTextSet] = React.useState('');

  const remoteName = React.useMemo((): string | undefined => {
    if (callCurrent === null) return undefined;

    return callCurrent.lastName + ' ' + callCurrent.firstName + ' ' + callCurrent.secondName;
  }, [
    callCurrent,
  ]);

  const operatorPrepare = React.useCallback(async () => {
    await mediaStart();
    operatorReadySet(true);
  }, [
    mediaStart,
  ]);

  const callStart = React.useCallback(async (e: { call: CallType, clientIdCaller: string }) => {
    const { clientIdCaller } = e;
    callCurrentSet(e.call);
    
    if (localMediaStream.current === null) return console.log('callStart - localMediaStream is null');

    peerConnection.current = new RTCPeerConnection({
      iceServers: [{
        urls: ['stun:80.90.189.135:3478']
      }],
    });

    try {
      for (const track of localMediaStream.current.getTracks()) {
        peerConnection.current.addTrack(track, localMediaStream.current);
      }
    } catch (error) {
      console.error(error);      
    }

    peerConnection.current.ontrack = ((event: RTCTrackEvent) => {
      event.track.onunmute = () => {
        if (peerMediaElements.current['remote']) {
          peerMediaElements.current['remote']!.srcObject = event.streams[0];
        }
      }
    });

    peerConnection.current.onconnectionstatechange = (e) => {
      if (peerConnection.current === null) return;
      const { connectionState } = peerConnection.current;
      const status = connectionState === 'connected' || connectionState === 'connecting'
        ? connectionState
        : connectionState === 'disconnected'
          ? 'connecting'
          : 'pending';

      if (status === 'connected') errorTextSet('');
      connectStatusSet(status)
    } 

    peerConnection.current.onnegotiationneeded = async () => {
      try {
        await peerConnection.current!.setLocalDescription();
        callSocket.emit(ACTIONS_CALL.OPERATOR_SEND_OFFER, {
          jwt: PersistanceService.getToken(),
          callUuid,
          offer: peerConnection.current!.localDescription,
        });
      } catch (error) {
        console.log(error);
      }
    };

    peerConnection.current.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      callSocket.emit(ACTIONS_CALL.RELAY_ICE, {
        iceCandidate: event.candidate,
        clientId: clientIdCaller,
      });
    };
  }, [
    callUuid,
    localMediaStream,
    peerConnection,
    peerMediaElements,
    callCurrentSet,
  ]);

  const callStop = React.useCallback(() => {
    mediaStop();
    noteSave();
    navigate('/call-list-queue');
  }, [
    navigate,
    mediaStop,
    noteSave,
  ]);

  const operatorCallStop = React.useCallback(() => {
    callSocket.emit(ACTIONS_CALL.OPERATOR_CALL_STOP, {
      callUuid,
      jwt: PersistanceService.getToken(),
    });
    callStop();
  }, [
    callUuid,
    callStop,
  ]);

  const answerSet = React.useCallback(async (event: { answer: RTCSessionDescriptionInit }) => {
    if (!peerConnection.current) {
      return console.warn('answerSet - peerConnection not exist');
    }

    await peerConnection.current.setRemoteDescription(
      new RTCSessionDescription(event.answer),
    );
  }, [
    peerConnection,
  ]);

  const iceCandidateSet = React.useCallback((event: { iceCandidate: RTCIceCandidate }) => {
    try {
      peerConnection.current!.addIceCandidate(event.iceCandidate);
    } catch (error) {
      console.log(error);
    }
  }, [
    peerConnection,
  ]);

  const callerCallStop = React.useCallback(() => {
    callStop();
    notifyCall({
      type: 'warning',
      message: 'Собеседник завершил звонок',
    });
  }, [
    callStop,
    notifyCall,
  ]);

  const callerLeave = React.useCallback(() => {
    errorTextSet('Собеседник покинул беседу');
    connectStatusSet('pending');
  }, [
  ]);

  const callerDisconnect = React.useCallback(() => {
    errorTextSet('Потеряна связь с собеседником');
    connectStatusSet('connecting');
  }, [
  ]);

  const cameraToggleAndSend = React.useCallback(() => {
    callSocket.emit(ACTIONS_CALL.OPERATOR_DEVICES_SET, {
      callUuid,
      cameraMuted: !cameraMuted,
      microphoneMuted,
    });
    cameraToggle();
  }, [
    callUuid,
    cameraMuted,
    microphoneMuted,
    cameraToggle,
  ]);

  const microphoneToggleAndSend = React.useCallback(() => {
    callSocket.emit(ACTIONS_CALL.OPERATOR_DEVICES_SET, {
      callUuid,
      cameraMuted,
      microphoneMuted: !microphoneMuted,
    });
    microphoneToggle();
  }, [
    callUuid,
    cameraMuted,
    microphoneMuted,
    microphoneToggle,
  ]);

  const callerDevicesSet = React.useCallback((event: { cameraMuted: boolean; microphoneMuted: boolean; }) => {
    remoteCameraMutedSet(event.cameraMuted);
    remoteMicrophoneMutedSet(event.microphoneMuted);
  }, []);

  React.useEffect(() => {
    operatorPrepare();

    return () => {
      mediaStop();
    }
  }, [
    callUuid,
    operatorPrepare,
    mediaStop,
  ]);

  return React.useMemo(() => ({
    connectStatus,
    operatorReady,
    remoteCameraMuted,
    remoteMicrophoneMuted,
    remoteName,
    errorText,
    callStart,
    operatorCallStop,
    callerCallStop,
    iceCandidateSet,
    answerSet,
    cameraToggleAndSend,
    microphoneToggleAndSend,
    callerDevicesSet,
    callerLeave,
    callerDisconnect,
  }), [
    connectStatus,
    operatorReady,
    remoteCameraMuted,
    remoteMicrophoneMuted,
    remoteName,
    errorText,
    callStart,
    operatorCallStop,
    callerCallStop,
    iceCandidateSet,
    answerSet,
    cameraToggleAndSend,
    microphoneToggleAndSend,
    callerDevicesSet,
    callerLeave,
    callerDisconnect,
  ]);
}

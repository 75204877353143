import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
  UserType,
  HandbkInterface,
} from '@app/types';

import { UserCreateRequest, UserPasswordUpdateRequest, UserUpdateRequest } from './types';


export class UserApi {
  public static async create(
    payload: UserCreateRequest,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('POST', '/users/create', payload);
  }

  public static async list(
  ): Promise<BaseResponseInterface<{ users: UserType[] }>> {
    return await HttpService.sendRequest('GET', '/users/list');
  }

  public static async one(
    id: number,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('GET', '/users/one', { id });
  }

  public static async handbk(
  ): Promise<BaseResponseInterface<{ handbk: HandbkInterface }>> {
    return await HttpService.sendRequest('GET', '/users/handbk');
  }

  public static async update(
    payload: UserUpdateRequest,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('POST', '/users/update', payload);
  }

  public static async passwordUpdate(
    payload: UserPasswordUpdateRequest,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('POST', '/users/password-update', payload);
  }
}

import { ConsultationListMyFeature, PageBaseLayout } from '@app/components'


export const ConsultationListMyPage = () => {
  return (
    <PageBaseLayout breadcrumbs={[
      { title: 'Главная', link: '/' },
      { title: 'Журнал консультаций', link: '/' },
    ]}>
      <ConsultationListMyFeature />
    </PageBaseLayout>
  );
};

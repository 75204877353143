import {
  PageBaseLayout,
  RoleCreateFeature,
} from '@app/components'


export const RoleCreatePage = () => {
  return (
    <PageBaseLayout
      breadcrumbs={[
        { title: 'Главная', link: '/' },
        { title: 'Роли', link: '/role-list' },
        { title: 'Новая роль', link: '/' },
      ]}
    >
      <RoleCreateFeature />
    </PageBaseLayout>
  );
};

import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useMainContext, useGeneralUpdateContext } from '../../context';


export const GeneralUpdateComponent = () => {
  const {
    generalIsEditing,
    generalFormClose,
  } = useMainContext();

  const {
    formValue,
    formDirty,
    formIsSumbitting,
    onSubmit,
    errorSet,
    changeFormValue,
  } = useGeneralUpdateContext();

  if (formValue === null || !generalIsEditing) return null;

  return (
    <FormCenteredBlock
      onSubmit={onSubmit}
      buttons={
        <>
          <ButtonSaveBlock
            loading={formIsSumbitting}
            onClick={onSubmit}
            disabled={!formDirty}
          />
          <ButtonCancelBlock onClick={generalFormClose}/>
        </>
      }
    >
      <FieldTextBlock
        label="Название"
        name="name"
        error={errorSet('name')}
        onChange={changeFormValue}
        value={formValue.name}
        required
      />
    </FormCenteredBlock>
  );
};

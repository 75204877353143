import { ConsultationType } from '@app/types';
import React from 'react';
import { useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import { guestAppHost } from 'config';
import { useAppStore } from '@app/stores';


export interface MainContextInterface {
  peerConnection: React.MutableRefObject<RTCPeerConnection | null>;
  localMediaStream: React.MutableRefObject<MediaStream | null>;
  peerMediaElements: React.MutableRefObject<{ [key: string]: HTMLVideoElement | null; }>
  
  consultationUuid: string | undefined;
  noteDrawerOpened: boolean;
  consultationCurrent: ConsultationType | null;
  consultationCurrentSet: React.Dispatch<React.SetStateAction<ConsultationType | null>>;
  noteDrawerOpen: () => void;
  noteDrawerClose: () => void;
  provideMediaRef: (id: string, node: HTMLVideoElement | null) => void;
  copyLink: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { uuid: consultationUuid } = useParams();
  const { notifyCall } = useAppStore();

  const peerConnection = React.useRef<RTCPeerConnection | null>(null);
  const localMediaStream = React.useRef<MediaStream | null>(null);
  const peerMediaElements = React.useRef<{ [key: string]: HTMLVideoElement | null }>({});
  const [noteDrawerOpened, noteDrawerOpenedSet] = React.useState(false);
  const [consultationCurrent, consultationCurrentSet] = React.useState<ConsultationType | null>(null);

  const provideMediaRef = React.useCallback((id: string, node: HTMLVideoElement | null) => {
    console.log('provideMediaRef')
    peerMediaElements.current[id] = node;
  }, [
    peerMediaElements,
  ]);

  const noteDrawerOpen = React.useCallback(() => {
    noteDrawerOpenedSet(true);
  }, []);

  const noteDrawerClose = React.useCallback(() => {
    noteDrawerOpenedSet(false);
  }, []);

  const copyLink = React.useCallback(() => {
    if (consultationUuid === undefined) return;

    const link = `${guestAppHost}/consultation/${consultationUuid}`;
    copy(link);
    notifyCall({
      type: 'info',
      message: 'Ссылка на консультацию скопирована в буфер обмена',
    });
  }, [
    consultationUuid,
    notifyCall,
  ]);

  return React.useMemo(() => ({
    peerConnection,
    localMediaStream,
    peerMediaElements,
    consultationUuid,
    noteDrawerOpened,
    consultationCurrent,
    consultationCurrentSet,
    noteDrawerOpen,
    noteDrawerClose,
    provideMediaRef,
    copyLink,
  }), [
    peerConnection,
    localMediaStream,
    peerMediaElements,
    consultationUuid,
    noteDrawerOpened,
    consultationCurrent,
    consultationCurrentSet,
    noteDrawerOpen,
    noteDrawerClose,
    provideMediaRef,
    copyLink,
  ]);
};

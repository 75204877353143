import {
  ButtonBrick,
  FieldSelectBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useFilterContext, useMainContext } from '../../context';


export const FilterComponent = () => {
  const {
    formValue,
    operatorList,
    changeFormValue,
    onSubmit,
  } = useFilterContext();

  const { filterReset } = useMainContext();

  return (
    <FormCenteredBlock
      onSubmit={onSubmit}
      buttons={<>
        <ButtonBrick
          onClick={onSubmit}
          size="large"
          variant="contained"
        >
          Применить
        </ButtonBrick>
        <ButtonBrick
          onClick={filterReset}
          size="large"
        >
          Сбросить
        </ButtonBrick>
      </>}
    >
      <FieldTextBlock
        label="Идентификатор"
        name='consultationId'
        value={formValue.consultationId}
        onChange={changeFormValue}
      />
      <FieldSelectBlock
        label="Статус"
        name='status'
        value={formValue.status || null}
        onChange={changeFormValue}
        items={[
          { value: 'in-flight', label: 'Звонок' },
          { value: 'done', label: 'Завершен' },
        ]}
      />
      <FieldSelectBlock
        label="Консультант"
        name='consultantId'
        value={formValue.consultantId || null}
        onChange={changeFormValue}
        items={operatorList.items}
      />
    </FormCenteredBlock>
  );
}

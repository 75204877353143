import { create } from 'zustand';

import {
  BackendErrorsType,
  RoleType,
  UserType,
} from '@app/types';

import { AuthApi } from '@app/api/auth/auth.api';
import { PersistanceService } from '@app/services';
import { AuthSigninRequest } from '@app/api/auth';
import { PersonalApi, PersonalDataUpdateRequest, PersonalPasswordUpdateRequest } from '@app/api';


export interface AuthStoreInterface {
  user: UserType | null;
  errors: BackendErrorsType | null;
  isLoading: boolean;
  signin: (payload: AuthSigninRequest) => Promise<boolean>;
  signinCurrent: () => Promise<void>;
  signout: () => void;
  dataUpdate: (payload: PersonalDataUpdateRequest) => Promise<boolean>;
  passwordUpdate: (payload: PersonalPasswordUpdateRequest) => Promise<boolean>;
  userUpdate: (user: UserType) => void;
  roleUpdate: (role: RoleType) => void;
};

export const useAuthStore = create<AuthStoreInterface>((set) => ({
  user: null,
  errors: null,
  isLoading: false,
  signin: async (
    payload: AuthSigninRequest,
  ): Promise<boolean> => {
    set({ errors: null });
    const response = await AuthApi.signin(payload);

    if (response.success === false) {
      set({ errors: response.errors as BackendErrorsType })
      return false;
    }

    set({ user: response.data.user });
    PersistanceService.setToken(response.data.user.token);

    return true;
  },
  signinCurrent: async (): Promise<void> => {
    set({ isLoading: true });
    const response = await AuthApi.current();
    set({ isLoading: false });

    if (!response.success) return;

    set({ user: response.data.user });
    PersistanceService.setToken(response.data.user.token);
  },
  signout: (): void => {
    PersistanceService.clearToken();
    set({ user: null });
  },
  dataUpdate: async (
    payload :PersonalDataUpdateRequest,
  ): Promise<boolean> => {
    set({ errors: null });
    const response = await PersonalApi.dataUpdate(payload);
    
    if (response.success === false) {
      set({ errors: response.errors as BackendErrorsType })
      return false;
    }
    
    set({ user: response.data.user });
    return true;
  },
  passwordUpdate: async (
    payload :PersonalPasswordUpdateRequest,
  ): Promise<boolean> => {
    set({ errors: null });
    const response = await PersonalApi.passwordUpdate(payload);
    
    if (response.success === false) {
      set({ errors: response.errors as BackendErrorsType })
      return false;
    }
    
    return true;
  },
  userUpdate: (user: UserType) => {
    set((state) => {
      if (state.user !== null && state.user.id === user.id) return { user };
      return state;
    });
  },
  roleUpdate: (role: RoleType) => {
    set((state) => {
      if (state.user === null) return state;
      if (state.user.role.id !== role.id) return state;

      return {
        user: {
          ...state.user,
          role,
        },
      };
    });
  }
}));

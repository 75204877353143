import React from 'react';

import {
  BackendErrorsType,
  ChangeFieldEventType,
  RoleType,
} from '@app/types';

import { useAppStore, useAuthStore } from '@app/stores';
import { translateErrorHelper } from '@app/helpers';
import { RoleApi } from '@app/api';

import { useMainContext } from '../main';


export interface GeneralUpdateContextInterface {
  formValue: RoleType | null;
  formDirty: boolean;
  formIsSumbitting: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
};

export const useGeneralUpdateHook = (
): GeneralUpdateContextInterface => {
  const { notifyCall } = useAppStore();
  const { roleUpdate } = useAuthStore();

  const {
    one,
    oneSet,
    generalFormClose,
    userAccessFormClose,
    appAccessFormClose,
  } = useMainContext();

  const [ formValue, formValueSet ] = React.useState<RoleType | null>(null);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSumbitting, formIsSubmittingSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (formValue === null) return;
    
    errorsSet(null);
    formIsSubmittingSet(true);
    const response = await RoleApi.update(formValue);
    formIsSubmittingSet(false);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось обновить роль.',
      });

      errorsSet(response.errors as BackendErrorsType);
      return;
    }
    
    notifyCall({
      type: 'success',
      message: 'Роль успешно обновлена.',
    });

    oneSet(response.data.one);
    roleUpdate(response.data.one);
    generalFormClose();
    userAccessFormClose();
    appAccessFormClose();
  }, [
    formValue,
    roleUpdate,
    notifyCall,
    generalFormClose,
    oneSet,
    userAccessFormClose,
    appAccessFormClose,
  ]);

  const errorSet = React.useCallback((fieldName: string): string | null => {
    return (errors && errors[fieldName]) ? translateErrorHelper(errors[fieldName][0]) : null;
  }, [
    errors,
  ]);

  React.useEffect(() => {
    formValueSet(one);
  }, [
    one,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSumbitting,
    changeFormValue,
    onSubmit,
    errorSet,
  }), [
    formValue,
    formDirty,
    formIsSumbitting,
    changeFormValue,
    onSubmit,
    errorSet,
  ]);
};

import {
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';

import { tranlsateRoleAccessHelper } from '@app/helpers';

import { useMainContext } from '../../context';


export const UserAccessTableComponent = () => {
  const {
    one,
    userAccessIsEditing,
  } = useMainContext();
  if (one === null || userAccessIsEditing) return null;

  return (
    <TableContainerBrick>
      <TableBrick aria-label="users">
        <TableHeadBrick>
          <TableRowBrick>
            <TableCellBrick>Таблица</TableCellBrick>
            <TableCellBrick>Создание</TableCellBrick>
            <TableCellBrick>Чтение</TableCellBrick>
            <TableCellBrick>Обновление</TableCellBrick>
          </TableRowBrick>
        </TableHeadBrick>
        <TableBodyBrick>
          <TableRowBrick
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCellBrick>Роли</TableCellBrick>
            <TableCellBrick>{tranlsateRoleAccessHelper(one.ROLE_CREATE)}</TableCellBrick>
            <TableCellBrick>{tranlsateRoleAccessHelper(one.ROLE_READ)}</TableCellBrick>
            <TableCellBrick>{tranlsateRoleAccessHelper(one.ROLE_UPDATE)}</TableCellBrick>
          </TableRowBrick>
          <TableRowBrick
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCellBrick>Пользователь</TableCellBrick>
            <TableCellBrick>{tranlsateRoleAccessHelper(one.USER_CREATE)}</TableCellBrick>
            <TableCellBrick>{tranlsateRoleAccessHelper(one.USER_READ)}</TableCellBrick>
            <TableCellBrick>{tranlsateRoleAccessHelper(one.USER_UPDATE)}</TableCellBrick>
          </TableRowBrick>
        </TableBodyBrick>
      </TableBrick>
    </TableContainerBrick>
  );
};

import { HttpService } from '@app/services';
import { BaseResponseInterface } from '@app/types';

import {
  ConsultationCloseRequest,
  ConsultationCloseResponse,
  ConsultationCreateRequest,
  ConsultationCreateResponse,
  ConsultationListGeneralRequest,
  ConsultationListGeneralResponse,
  ConsultationListInFlightByUserResponse,
  ConsultationListMyRequest,
  ConsultationListMyResponse,
  ConsultationNoteSetRequest,
  ConsultationNoteSetResponse,
} from './types';


export class ConsultationApi {
  public static async listGeneral(
    payload: ConsultationListGeneralRequest,
  ): Promise<BaseResponseInterface<ConsultationListGeneralResponse>> {
    return await HttpService.sendRequest('GET', '/consultations/list-general', payload);
  }

  public static async listMy(
    payload: ConsultationListMyRequest,
  ): Promise<BaseResponseInterface<ConsultationListMyResponse>> {
    return await HttpService.sendRequest('GET', '/consultations/list-my', payload);
  }

  public static async create(
    payload: ConsultationCreateRequest,
  ): Promise<BaseResponseInterface<ConsultationCreateResponse>> {
    return await HttpService.sendRequest('POST', '/consultations/create', payload);
  }

  public static async listInFlightByUser(
  ): Promise<BaseResponseInterface<ConsultationListInFlightByUserResponse>> {
    return await HttpService.sendRequest('GET', '/consultations/list-in-flight-by-user');
  }

  public static async noteSet(
    payload: ConsultationNoteSetRequest,
  ): Promise<BaseResponseInterface<ConsultationNoteSetResponse>> {
    return await HttpService.sendRequest('POST', '/consultations/note-set', payload);
  }

  public static async close(
    payload: ConsultationCloseRequest,
  ): Promise<BaseResponseInterface<ConsultationCloseResponse>> {
    return await HttpService.sendRequest('POST', '/consultations/close', payload);
  }
}

const audioCtx = new AudioContext();

export class BeepService {
  static beep(
  ): any {
    audioCtx.resume().then(() => {
      const oscillator = audioCtx.createOscillator(); 
      oscillator.type = 'sine'; 
      oscillator.connect(audioCtx.destination); 
      oscillator.start(); 
      oscillator.stop(audioCtx.currentTime + 0.5); 
    })
  }
}

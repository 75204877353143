import {
  ButtonBackBlock,
  ButtonCancelBlock,
  ButtonEditBlock,
  CardDataComposition,
} from '@app/components';

import { useMainContext } from '../../context';


export const GeneralComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    roleId,
    oneIsLoading,
    generalIsEditing,
    generalFormOpen,
    generalFormClose,
  } = useMainContext();

  return (
    <CardDataComposition
      title={'Роль №' + roleId}
      extra={
        <>
          {!generalIsEditing && <ButtonEditBlock onClick={() => generalFormOpen()} />}
          {!generalIsEditing && <ButtonBackBlock buttonIcon />}
          {generalIsEditing && <ButtonCancelBlock onClick={() => generalFormClose()} buttonIcon />}
        </>
      }
      loading={oneIsLoading}
      mb12
    >
      {children}
    </CardDataComposition>
  );
}

import React from 'react';

import { useBreadcrumbsStore } from '@app/stores';

import {
  CallListQueueFeature,
  PageBaseLayout,
} from '@app/components';


export const CallListQueuePage = () => {
  const {
    breadcrumbs,
    breadcrumbsCallsSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsCallsSet();
  }, [
    breadcrumbsCallsSet,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <CallListQueueFeature />
    </PageBaseLayout>
  );
};

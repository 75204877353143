import * as React from 'react';

import { useAppStore } from '@app/stores';
import { CallType, ConsultationType } from '@app/types';
import { CallApi, ConsultationApi } from '@app/api';


export type MainContextInterface = {
  entityId: number | null;
  title: string;
  noteValue: string;
  noteDirty: boolean;
  noteSubmitting: boolean;
  noteChange: (note: string) => void;
  cancelHandler: () => void;
  saveHandler: (e: React.SyntheticEvent) => void;
}

export const useMainHook = (
  entityId: number | null,
  type: 'call' | 'consultation',
  title: string,
  note: string,
  closeModal: () => void,
  entityUpdate: (call: CallType | ConsultationType) => void,
): MainContextInterface => {
  const { notifyCall } = useAppStore();

  const [ noteValue, noteValueSet ] = React.useState('');
  const [ noteDirty, noteDirtySet ] = React.useState(false);
  const [ noteSubmitting, noteSubmittingSet ] = React.useState(false);

  const noteChange = React.useCallback((note: string) => {
    noteValueSet(note);
    noteDirtySet(true);
  }, []);

  const cancelHandler = React.useCallback(() => {
    if (entityId === null) return;

    noteValueSet(note);
    noteDirtySet(false);
    closeModal();
  }, [
    note,
    entityId,
    closeModal,
  ]);

  const saveHandler = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (entityId === null) return;

    noteSubmittingSet(true);
    if (type === 'call') {
      const response = await CallApi.noteSet({
        callId: entityId,
        note: noteValue,
      });
      noteSubmittingSet(false);

      if (!response.success) {
        return notifyCall({
          type: 'error',
          message: 'Не удалось сохранить заметку',
        });
      }

      entityUpdate(response.data.call);
    }
    else if (type === 'consultation') {
      const response = await ConsultationApi.noteSet({
        consultationId: entityId,
        note: noteValue,
      });
      noteSubmittingSet(false);

      if (!response.success) {
        return notifyCall({
          type: 'error',
          message: 'Не удалось сохранить заметку',
        });
      }

      entityUpdate(response.data.one);
    }

    notifyCall({
      type: 'success',
      message: 'Данные успешно обновлены',
    });
    noteDirtySet(false);
    closeModal();
  }, [
    type,
    entityId,
    noteValue,
    entityUpdate,
    notifyCall,
    closeModal,
  ]);

  React.useEffect(() => {
    if (entityId !== null) noteValueSet(note || '');
    else noteValueSet('');
  }, [
    entityId,
    note,
  ]);

  return React.useMemo(() => ({
    entityId,
    title,
    noteValue,
    noteDirty,
    noteSubmitting,
    cancelHandler,
    noteChange,
    saveHandler,
  }), [
    entityId,
    title,
    noteValue,
    noteDirty,
    noteSubmitting,
    cancelHandler,
    noteChange,
    saveHandler,
  ]);
}

import { TransitionOpacityBrick } from '@app/agui/bricks';
import { CallSmart, EditNoteIcon } from '@app/components';

import {
  useCallContext,
  useDevicesContext,
  useMainContext,
} from '../../context';
import { NoteComponent } from '../note';


export const MainComponent = () => {
  const { peerMediaElements, noteDrawerOpen } = useMainContext();
  const {
    connectStatus,
    remoteCameraMuted,
    remoteMicrophoneMuted,
    remoteName,
    errorText,
    cameraToggleAndSend,
    microphoneToggleAndSend,
  } = useCallContext();
  
  const {
    cameraDeviceId,
    cameraMuted,
    cameraList,
    cameraSet,
    microphoneDeviceId,
    microphoneMuted,
    microphoneList,
    microphoneSet,
  } = useDevicesContext();

  const { operatorCallStop } = useCallContext();

  return (
    <TransitionOpacityBrick>
      <CallSmart
        remoteName={remoteName}
        peerMediaElements={peerMediaElements}
        connectStatus={connectStatus}
        localMicrophoneMuted={microphoneMuted}
        localCameraMuted={cameraMuted}
        remoteMicrophoneMuted={remoteMicrophoneMuted}
        remoteCameraMuted={remoteCameraMuted}
        cameraDeviceId={cameraDeviceId}
        microphoneDeviceId={microphoneDeviceId}
        cameraList={cameraList}
        microphoneList={microphoneList}
        microphoneToggle={microphoneToggleAndSend}
        cameraToggle={cameraToggleAndSend}
        cameraSet={cameraSet}
        microphoneSet={microphoneSet}
        callStop={operatorCallStop}
        errorText={errorText}
        extra={[
          { icon: <EditNoteIcon />, cb: noteDrawerOpen },
        ]}
      />

      <NoteComponent />
    </TransitionOpacityBrick>
  );
};

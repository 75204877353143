import { useNavigate } from 'react-router-dom';

import {
  ButtonConfirmComposition,
  ButtonCreateBlock,
  CardDataComposition,
  LinkBrick,
  NoteSmart,
  ScrollXZoneBrick,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';
import { translateDateHelper } from '@app/helpers';

import { useMainContext } from '../../context';


export const MainComponent = () => {
  const navigate = useNavigate();
  const {
    consultationList,
    consultationListIsLoading,
    consultationSelected,
    noteModalOpen,
    noteModalClose,
    consultationUpdate,
    consultationClose,
  } = useMainContext();

  return (
    <CardDataComposition
      title="Консультации"
      noData={consultationList.length === 0}
      extra={
        <ButtonCreateBlock
          onClick={() => navigate('/consultation-list/create')}
          buttonIcon
        />
      }
      loading={consultationListIsLoading}
    >
      <ScrollXZoneBrick>
        <TableContainerBrick>
          <TableBrick aria-label="roles">
            <TableHeadBrick>
              <TableRowBrick>
                <TableCellBrick>ID</TableCellBrick>
                <TableCellBrick>Идентификатор</TableCellBrick>
                <TableCellBrick>Дата создания</TableCellBrick>
                <TableCellBrick></TableCellBrick>
                <TableCellBrick></TableCellBrick>
              </TableRowBrick>
            </TableHeadBrick>
            <TableBodyBrick>
              {consultationList.map((item) => (
                <TableRowBrick
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={item.id}
                >
                  <TableCellBrick component="th" scope="row">
                    {item.id}
                  </TableCellBrick>
                  <TableCellBrick>
                    <LinkBrick to={'/consultation-list/' + item.uuid} label={item.consultationId} />
                  </TableCellBrick>
                  <TableCellBrick>{translateDateHelper(item.createdAt)}</TableCellBrick>
                  <TableCellBrick><LinkBrick label="Подробнее" onClick={() => noteModalOpen(item)} /></TableCellBrick>
                  <TableCellBrick>
                    <ButtonConfirmComposition
                      buttonText="Закрыть"
                      type="link"
                      message="Текущая консультация будет закрыта"
                      onClick={() => consultationClose(item.id)}
                    />
                  </TableCellBrick>
                </TableRowBrick>
              ))}
            </TableBodyBrick>
          </TableBrick>
        </TableContainerBrick>
      </ScrollXZoneBrick>

      <NoteSmart
        entityId={(consultationSelected && consultationSelected.id) || null}
        type="consultation"
        title={consultationSelected?.consultationId || ''}
        note={consultationSelected?.note || ''}
        closeModal={noteModalClose}
        entityUpdate={consultationUpdate}
      />
    </CardDataComposition>
  );
};
